<template>
    <form class="order vue-search">
        <i class="icon-balloon vue-pointer"></i>
        <div class="vue-address-ddt__wrapper" :class="{wrapperCity: city}">
            <VueSuggestions
                    :model.sync="city"
                    :id.sync="id"
                    :coordinates.sync="coordinates"
                    :placeholder="placeholder"
                    :name="'address'"
                    :class="'form-control vue-address-ddt'"
                    :disabled="searchSpinner === true"
                    :options="suggestionOptions">
            </VueSuggestions>
            <span class="address-placeholder">Ваш адрес</span>
            <!-- :value="curAddress" -->
            <input
                    @click="submitForm"
                    :disabled="searchSpinner === true"
                    type="submit"
                    class="button-search-vue"
                    name="button"
                    v-model="btnSearch">
            <!--            <span class="vue-message-search"-->
            <!--                  :class="{ 'vue-message-show': showMessage === true }">-->
            <!--                  {{ message }}-->
            <!--            </span>-->
            <Spinner :color="color"
                     :class="{ 'vue-hide': searchSpinner === false }"
                     class="vue-spinner-search"></Spinner>
        </div>
    </form>
</template>
<script>
	import VueSuggestions from './VueSuggestions';
	import Spinner from 'vue-spinner/src/BeatLoader'
	import axios from 'axios';
	import cookie from 'vue-cookie';

	export default {
		data() {
			return {
				userKladrCityId: null,
				showPopUp: true,
				cityName: 'Санкт-Петербург',
				urlSearch: '',
				searchSpinner: false,
				fiasId: '',
				house: null,
				city: '',
				citySelect: '',
				id: '',
				curAddress: '',
				coordinates: {
					latitude: '',
					longitude: ''
				},
				message: 'Выберите адрес из списка',
				timerMessage: '',
				showMessage: false,
				btnSearch: 'Поиск',
				post: null,
				error: null,
				url: 'https://inetme.ru/dev/vue/search/vue.php',
				form: '',
				control: '',
				color: '#363636',
                placeholder: 'Введите адрес (например, Москва, Пушкина, 15)',
                width: 0,
				suggestionOptions: {
					formatResult: this.formatResult,
					// token: '1901b9fe85acf19f459cb43a2ba334e11e97ee75',
					token: '1f7f69ef11aeaa181446230395f6d18a3856ae9b',
                    type: "ADDRESS",
                    hint: 'Пожалуйста, выберите свой адрес из списка доступных',
					onSearchStart: this.onSearchStart,
					geoLocation: false,
					scrollOnFocus: false,
					triggerSelectOnBlur: false,
					triggerSelectOnEnter: false,
					addon: 'none',
					deferRequestBy: 500,
					onSelect: this.onSelect
				},
			}
		},
		components: {VueSuggestions, Spinner, cookie},
		created() {
			this.knowKladrId();
			this.curAddress = cookie.get('vue-search-address');
			cookie.delete('vue-search-address');
			Event.$on('citySearch', value => {
				this.cityName = value;
				this.$set(this.suggestionOptions, 'onSearchStart', value);
            });
            (window.innerWidth < 480) ? this.placeholder = 'Москва, Пушкина, 15' : this.placeholder = 'Введите адрес (например, Москва, Пушкина, 15)'
            window.addEventListener('resize', this.updateWidth)
		},
		mounted() {
			$('.icon-balloon.vue-pointer').on('click', function () {
				$("#cityForm").reveal({
					animation: "fade",
					animationspeed: 100
				})
			});
		},
		watch: {
			showMessage: function () {
				clearTimeout(this.timerMessage);
				if (!this.showMessage) return;
				this.timerMessage = setTimeout(() => {
					this.showMessage = false;
				}, 3000);
			},
			city: function () {
				if (this.showMessage) return;
				this.showMessage = true;
				this.fiasId = '';
				this.btnSearch = "Поиск";
			}
		},
		methods: {
			openForm: function () {
				// alert('открываем попап с формой');
				var e = $("#callFormSubscribe form").attr("action");
				if (-1 == e.indexOf("?")) {
					$("#callFormSubscribe form").attr("action", e + "?ga_type=header")
				} else {
					$("#callFormSubscribe form").attr("action", e + "&ga_type=header");
				}
				var t = $("#callFormSubscribe .agree input").attr("id");
				$("#callFormSubscribe .agree input").attr("id", t + "_header");
				$("#callFormSubscribe .agree label").attr("for", t + "_header");
				$("#callFormSubscribe .provider-person2, #callFormSubscribe .hide-area").hide();
				if (this.citySelect){
					$('#callFormSubscribe [name="form_text_152"]').attr("value", this.citySelect).val(this.citySelect);
                }else{
					$('#callFormSubscribe [name="form_text_152"]').attr("value", this.city).val(this.city);
				}
				$('#callFormSubscribe [name="form_text_153"]').attr("value", location.href).val(location.href);
				if ($("#callFormSubscribe .not_found_text").length === 0) {
					$("#callFormSubscribe .form_title").after("<div class='not_found_text'>Введенный вами адрес не найден в автоматическом режиме. Закажите звонок, менеджер проверит вручную техническую возможность и свяжется с вами</div>");
				}
				$("#callFormSubscribe").reveal({
					animation: "fade",
					animationspeed: 100
				});
				gtag("event", "call_me_search_click");
			},
			onSelect: function (suggestion) {
				//if (suggestion.data.house === null) return false;
				this.searchSpinner = true;
				this.fiasId = suggestion.data.street_fias_id;
				if (suggestion.data.house !== null) {
					this.house = suggestion.data.house.replace(/\//g, '-');
				}
				console.log(suggestion.data);
				cookie.set('vue-search-address', suggestion.data.source, 1);

				if (suggestion.data.block_type !== null) {
					if (suggestion.data.block_type === 'к') {
						this.house += `к${suggestion.data.block.replace(/\s/g, '')}`;
					} else {
						this.house += suggestion.data.block_type + suggestion.data.block.replace(/\s/g, '');
					}
				}
				console.log(this.house);

				axios.get(`${this.url}?fiasID=${this.fiasId}&house=${this.house}&string=${suggestion.data.source}`, {
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				})
					.then(response => {
						if (response.data === "/address_not_found/") {
							this.showPopUp = true;
							// this.urlSearch = `/${vueCode}${response.data}`;
						} else {
							this.showPopUp = false;
							this.urlSearch = response.data;
							this.btnSearch = "Найти";
						}
						this.searchSpinner = false;

						if (this.house === null) this.$nextTick(() => this.$el[0].focus())

					})
					.catch(e => {
						this.error = e;
						console.log("Error - " + e);
					});
			},
			submitForm: function (e) {
				e.preventDefault();
				if (this.showPopUp) {
					this.openForm();
				} else {
					if (this.fiasId !== ''/* || this.house !== null*/) {
						this.searchSpinner = true;
						document.location.href = this.urlSearch + location.search;
					} else {
						this.showMessage = true;
						this.$el[0].focus();
					}
				}
			},
			showModal: function () {
				Event.$emit('showPanel', false);
				Event.$emit('showModal', true);
			},
			formatResult: function (value, currentValue, suggestion) {
				let addressValue = this.makeAddressString(suggestion.data);
				suggestion.value = addressValue;
				//console.log(addressValue);
				return addressValue;
			},
			makeAddressString: function (address) {
				return this.join([
					this.join([address.region, address.region_type], " "),
					this.join([address.settlement_type, address.settlement], " "),
					this.join([address.area_type, address.area], " "),
					(address.city !== address.region && this.join([address.city_type, address.city], " ") || ""),
					this.join([address.street_type, address.street], " "),
					this.join([address.house_type, address.house, address.block_type, address.block], " "),
					this.join([address.flat_type, address.flat], " ")
				]);
			},

            updateWidth() {
                this.width = window.innerWidth;
                (this.width < 480) ? this.placeholder = 'Москва, Пушкина, 15' : this.placeholder = 'Введите адрес (например, Москва, Пушкина, 15)'
            },

			knowKladrId: function () {
				let userKladrCityId = localStorage.getItem('userKladrCityId');
				if(userKladrCityId){
					this.userKladrCityId = userKladrCityId;
                }else{
					axios
                        .get(
						'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip=',
                        {
                        	headers: {
                        		'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': `Token ${this.suggestionOptions.token}`,
                            },
                        })
                        .then(response => {
							try {
								this.userKladrCityId = response.data.location.data.city_kladr_id;
								localStorage.setItem('userKladrCityId', this.userKladrCityId);
							} catch (error) {
//								throw new Error(error);
							}
                        })
                    ;
                }
			},
			onSearchStart: function (query) {
				let input = query.query;
				// query.query = this.cityName + ' ' + input;
				query.query = input;
				if(this.userKladrCityId){
					query.locations_boost = [{
						"kladr_id": this.userKladrCityId
					}];
				}
				return query;
			},
			join: function (arr) {
				let separator = arguments.length > 1 ? arguments[1] : ", ";
				return arr.filter(function (n) {
					return n
				}).join(separator);
			}
		}
	}
</script>
<style lang="scss">
    .vue-address-ddt{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        line-height: 17px;
    }

    .suggestions-hint {
        font-weight: normal;
        font-size: 16px;
        line-height: 15px;
        text-align: left;
        margin-left: 15px;
        margin-bottom: 21px;
        margin-top: 20px;
        display: block;
        position: relative;
    }
    .suggestions-wrapper {
        &::after{
            opacity: 0;
            content: '';
            display: block;
            position: absolute;
            width: calc(100% - 160px);
            height: 1px;
            background: #E0E0E0;
            left: 10px;
            top: 0;
            z-index: 21;
        }
    }

    input.button-search-vue{
        background-position: -37px 19px !important;
        height: 50px !important;
    }

    input.button-search-vue + .suggestions-wrapper::after {
        opacity: 1;
    }

    .vue-search .icon-balloon {
        z-index: 12 !important;
        top: 12px !important;
    }

    .vue-address-ddt__wrapper {
        position: relative;
        span {
            position: absolute;
            left: 60px;
            top: 4px;
            font: 400 10px 'GothaPro';
            color: #363636;
            letter-spacing: -0.5px;
            transition: .35s ease-out all;
            transform-origin: (0 0);
            z-index: 11 !important;
            transform: translateY(-11px) scale(0);
            opacity: 0;
            @media screen and (max-width: 480px) {
                left: 20px;
            }
        }
        .vue-address-ddt.suggestions-input:focus + div + span {
            opacity: 1;
            transform: scale(1);
        }
    }
    .wrapperCity span {
        opacity: 1;
        transform: scale(1);
    }
    .suggestions-suggestions {
        padding-top: 50px;
        transform: translateY(-50px) translateX(-3px);
        border-radius: 25px 0px 10px 10px;
            z-index: 10 !important;
        width: calc(100% - 126px) !important;
        .suggestions-suggestion {
            min-height: 40px;
            display: flex;
            align-items: center;
            text-align: left;
            padding-left: 20px !important;
            max-width: 100%;
        }
        @media screen and (max-width: 480px) {
            width: 100% !important;
            transform: translateY(-50px) translateX(10px);
            border-radius: 20px;
            box-shadow: 0 0 5px -2px #000;
            overflow: hidden;
        }
    }

    .button-search-vue {
        z-index: 12 !important;
        @media screen and (max-width: 480px) {
            position: relative !important;
            width: 100% !important;
            z-index: 1 !important;
        }
    }

    ::-webkit-input-placeholder {transition: .35s ease-out all;}
    ::-moz-placeholder          {transition: .35s ease-out all;}
    :-moz-placeholder           {transition: .35s ease-out all;}
    :-ms-input-placeholder      {transition: .35s ease-out all;}

    :focus::-webkit-input-placeholder {color: transparent !important}
    :focus::-moz-placeholder          {color: transparent !important}
    :focus:-moz-placeholder           {color: transparent !important}
    :focus:-ms-input-placeholder      {color: transparent !important}

    .suggestions-wrapper {
        width: 100%
    }

    input.form-control.vue-address-ddt.suggestions-input {
        z-index: 11 !important;
        position: relative;
        height: 50px !important;
        transition: .35s ease-out all;
        &:focus {
            padding-top: 0px !important;
            &::paceholder{
                opacity: 0;
            }
        }
        @media screen and (max-width: 480px) {
            padding-right: 20px !important;
        }
    }

    .wrapperCity span {
    z-index: 1;
    }

    .vue-search .icon-balloon {
    z-index: 2;
    }

    /* start: vue-style; */
    .vue-pointer {
        cursor: pointer;
    }

    .vue-hide {
        display: none;
    }

    .vue-bold {
        font-weight: bold !important;
        opacity: .8;
    }

    /* end: vue-style; */


    /* start: fix header */
    .new_buy .form--callback {
        width: 80%;
    }

    .top-line {
        overflow: visible !important;
        height: 85px;
    }

    /* end: fix header */


    /* start: vue-search */
    .vue-spinner-search {
        position: absolute;
        padding-top: 12px;
        padding-left: 12px;
        z-index: 2;
        right: 0px;
        top: 0px;
        width: 118px;
        height: 31px;
        cursor: wait;
    }

    .suggestions-wrapper {
        position: relative;
    }

    .vue-scroll-area {
        position: relative;
        margin: auto;
    }

    .vue-search .icon-balloon {
        cursor: pointer;
        position: absolute;
        left: 20px;
        top: 9px;
    }

    .vue-search input[type="submit"].button-search-vue {
        height: 43px;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        background-position: -47px 13px;
    }

    .vue-search input[type="submit"].button-search-vue:hover {
        background-position: -67px -41px;
    }

    .vue-search input[type="submit"].button-search-vue:disabled {
        color: #f9c600;
    }

    .vue-search input[type="text"].vue-address-ddt.suggestions-input {
        width: 100%;
        height: 43px;
        padding: 16px 140px 11px 60px;
        font-size: 17px;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        display: block;
        border: 0px;
        border-radius: 30px;
    }

    .vue-search input[type="text"].vue-address-ddt.suggestions-input:disabled {
        cursor: wait;
    }

    .vue-message-search {
        width: 0px;
        opacity: 0;
        background: inherit;
        position: absolute;
        color: red;
        top: 15px;
        white-space: nowrap;
        right: 140px;
        transition: all .3s ease-in-out;
        transform: scaleY(.8);
    }

    .vue-message-search.vue-message-show {
        width: 220px;
        opacity: 1;
        transform: scale(1);
    }

    /* end: vue-search */


    /* start: vue-panel */
    .vue-check-city {
        position: relative;
    }

    .vue-check-city * {
        font-size: 16px;
        line-height: normal;
        box-sizing: content-box;
    }

    .vue-check-city > p.vue-check-city-cur {
        color: #612366;
        font-size: 16px;
    }

    .vue-img-arrow-down {
        vertical-align: middle;
    }

    .vue-check-city-block {
        top: 30px;
        left: 0;
        padding: 20px;
        position: absolute;
        width: 250px;
        height: 90px;
        background: #fff;
        border: 1px solid #631a66;
        border-radius: 10px;
    }

    .vue-check-city-block span {
        cursor: pointer;
        display: inline-block;
        font-size: 14px !important;
        padding-top: 8px;
        color: #631a66 !important;
        border-bottom: 1px dashed;
        margin-left: 20px;
    }

    .vue-check-city-block span:hover {
        border-bottom: 1px solid;
    }

    .vue-check-city-block button {
        display: inline-block;
        outline: none;
        font-size: 14px;
        float: left;
        cursor: pointer;
        color: #fff;
        line-height: 18px;
        padding: 5px 20px;
        text-align: center;
        background: #612366;
        -webkit-transition: all 0.1s 0s;
        transition: all 0.1s 0s;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        border: 1px solid transparent;
    }

    .vue-check-city-block button:hover {
        background: none;
        color: #612366;
        border-color: #612366;
    }

    .vue-check-city-block .vue-title {
        font-weight: bold;
        color: #363636;
        padding: 0 0 10px;
        text-align: left;
    }

    .vue-check-city-block .vue-city {
        font-size: 14px;
        text-align: left;
        padding: 0 0 20px;
    }

    /* end: vue-panel */


    /* start: vue-modal */
    .vue-modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .vue-modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .vue-modal-container {
        height: 530px;
        position: relative;
        width: 730px;
        margin: 0 auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
    }

    .vue-modal-header h3 {
        margin-top: 0;
        font-size: 20px;
        text-align: left;
    }

    .vue-modal-body {
        margin: 20px 0;
    }

    .vue-modal-default-button {
        float: right;
    }

    .vue-modal-close {
        font-size: 36px;
        position: absolute;
        top: 20px;
        right: 20px;
        font-weight: 500;
        color: #000;
        cursor: pointer;
        text-decoration: none;
        line-height: 16px;
    }

    .vue-modal-close:hover {
        color: #000;
        text-decoration: none;
    }

    .vue-modal-main-region {
        border-bottom: 1px solid #d7d7d7;
        width: 100%;
        text-align: left;
        /*padding: 10px 0;*/
    }

    .vue-modal-body .vue-modal-main-region p {
        padding-right: 20px;
        font-size: 16px;
        color: #363636;
        text-decoration: none;
        display: inline-block;
        margin: 0;
    }

    .vue-modal-body .vue-modal-main-region p.active {
        color: red;
    }

    .vue-modal-body .vue-modal-main-region p:hover {
        text-decoration: underline;
    }

    .vue-modal-left-column {
        float: left;
        max-height: 490px;
        width: 370px;
        overflow: hidden;
        border-right: 1px solid #d7d7d7;
        padding-right: 5px;
    }

    .vue-modal-left-column .vue-scrollbar p {
        display: inline-block;
        padding-top: 5px;
        font-size: 16px;
        color: #363636;
        padding-left: 40px;
        margin-bottom: 0;
    }

    .vue-modal-left-column .vue-scrollbar p:hover {
        text-decoration: underline;
    }

    .vue-modal-left-column .vue-scrollbar > div > p {
        display: block;
        padding-left: 0;
        /*padding-bottom: 10px;*/
    }

    .vue-modal-left-column .vue-scrollbar span + p {
        padding-top: 15px;
    }

    .vue-modal-left-column .vue-scrollbar span {
        font-size: 16px;
        border-radius: 50%;
        background: #d7d7d7;
        width: 30px;
        height: 30px;
        display: block;
        text-align: center;
        line-height: 34px;
        margin-top: 8px;
        position: absolute;
    }

    .vue-modal-middle-column {
        min-height: 1px;
        float: left;
        padding-left: 15px;
        max-height: 408px;
        width: 360px;
        margin-top: 10px;
        padding-right: 5px;
        overflow: hidden;
        /*border-right: 1px solid #d7d7d7;*/
    }

    .vue-midal-left-column p:hover, .vue-modal-middle-column p:hover {
        text-decoration: underline;
    }

    .vue-modal-left-column .vue-scrollbar p.active,
    .vue-modal-middle-column .vue-scrollbar p.active {
        color: red;
    }

    .vue-modal-right-column {
        float: left;
        width: 360px;
        position: relative;
        padding-left: 15px;
    }

    .vue-modal-middle-column .vue-scrollbar {
        max-height: 405px;
    }

    .vue-modal-middle-column .vue-scrollbar p {
        display: inline-block;
        padding-left: 9px;
        padding-top: 5px;
        font-size: 16px;
        color: #363636;
        margin-bottom: 0;
    }

    .vue-modal-middle-column .vue-scrollbar span {
        font-size: 16px;
        margin-top: 5px;
        border-radius: 50%;
        background: #d7d7d7;
        width: 30px;
        height: 30px;
        display: block;
        text-align: center;
        line-height: 34px;
    }

    .vue-modal-right-column i.vue-input-active {
        display: inline-block;
        font-size: 20px;
        position: absolute;
        top: 9px;
        font-style: normal;
        font-weight: 700;
        color: red;
        cursor: pointer;
        text-decoration: none;
        left: 290px;
        background: #fff;
        height: 20px;
        width: 20px;
    }

    .vue-modal-right-column .vue-main-input-city {
        outline: none;
        border: 1px solid #d7d7d7;
        width: 260px;
        /*margin-left: 20px;*/
        height: 10px;
        border-radius: 15px;
        padding: 10px 30px 10px 10px;
        background: url(/dev/vue/search/src/assets/vue-loop.png) no-repeat 275px 8px;
    }

    .vue-modal-right-column .vue-scrollbar {
        margin-top: 20px;
    }

    .vue-modal-columns > div {
        box-sizing: border-box;
    }

    .vue-modal-columns {
        position: relative;
        padding-top: 20px;
        text-align: left;
    }

    .vue-scrollbar {
        max-height: 450px;
    }

    .vue-modal-mask.modal-enter {
        opacity: 0;
    }

    .vue-modal-mask.modal-leave-active {
        opacity: 0;
    }

    .vue-modal-mask.modal-enter .modal-container,
    .vue-modal-mask.modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    /* end: vue-modal */


    /* start: vue-scrollbar */
    .vue-scrollbar-transition, .vue-scrollbar__scrollbar-vertical, .vue-scrollbar__scrollbar-horizontal {
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }

    .vue-scrollbar-transition--scrollbar {
        transition: opacity 0.5s linear;
        -moz-transition: opacity 0.5s linear;
        -webkit-transition: opacity 0.5s linear;
        -o-transition: opacity 0.5s linear;
    }

    .vue-scrollbar__wrapper {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        background: white;
    }

    .vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-vertical, .vue-scrollbar__wrapper:hover .vue-scrollbar__scrollbar-horizontal {
        opacity: 1;
    }

    .vue-scrollbar__scrollbar-vertical, .vue-scrollbar__scrollbar-horizontal {
        opacity: 0.5;
        position: absolute;
        background: transparent;
    }

    .vue-scrollbar__scrollbar-vertical:hover, .vue-scrollbar__scrollbar-horizontal:hover {
        background: rgba(0, 0, 0, 0.3);
    }

    .vue-scrollbar__scrollbar-vertical .scrollbar, .vue-scrollbar__scrollbar-horizontal .scrollbar {
        position: relative;
        background: rgba(0, 0, 0, 0.5);
        cursor: default;
    }

    .vue-scrollbar__scrollbar-vertical {
        width: 10px;
        height: 100%;
        top: 0;
        right: 0;
    }

    .vue-scrollbar__scrollbar-vertical .scrollbar {
        width: 10px;
    }

    .vue-scrollbar__scrollbar-horizontal {
        height: 10px;
        width: 100%;
        bottom: 0;
        right: 0;
    }

    .vue-scrollbar__scrollbar-horizontal .scrollbar {
        height: 10px;
    }

    /* end: vue-scrollbar */

    @media screen and (max-width: 1170px) {
        .vue-search input[type="submit"].button-search-vue:hover {
            background-position: -67px -41px;
        }
    }

    @media screen and (max-width: 1024px) {
        .vue-search input[type="submit"].button-search-vue {
            padding-left: 0;
        }
    }

    @media screen and (max-width: 990px) {
        .vue-modal-container {
            width: 650px;
        }
        .vue-modal-left-column {
            width: 350px;
        }
        .vue-modal-middle-column {
            width: 290px;
        }
        .vue-modal-right-column .vue-main-input-city {
            width: 240px;
            /*margin-left: 10px;*/
            background-position: 255px 8px;
        }
        .vue-modal-right-column {
            width: 290px;
        }
        .vue-modal-right-column i.vue-input-active {
            left: 270px;
        }
    }

    @media screen and (max-width: 680px) {
        .vue-check-city-block {
            right: 0;
            left: auto;
        }
    }

    @media screen and (max-width: 679px) {
        .top-line {
            height: 55px;
        }
    }

    @media screen and (max-width: 750px) {
        .vue-modal-container {
            height: 470px;
            width: 430px;
        }
        .vue-modal-right-column {
            padding-left: 0;
            position: absolute;
            top: 8px;
            width: 440px;
        }
        .vue-modal-columns {
            padding-top: 50px;
        }
        .vue-modal-right-column .vue-main-input-city {
            width: 390px;
            background-position: 400px 8px;
            margin: 0;
        }
        .vue-modal-right-column i.vue-input-active {
            left: 400px;
        }
        .vue-modal-middle-column {
            width: 200px;
            margin-left: 10px;
            border-right: none;
            margin-top: 0;
            max-height: 410px;
            padding-left: 0;
        }
        .vue-modal-left-column .vue-scrollbar p {
            padding-right: 10px;
        }
        .vue-modal-left-column {
            width: 220px;
            max-height: 410px;
        }
        .vue-modal-body {
            margin: 0;
        }
        .vue-scrollbar {
            height: 380px;
        }
        .vue-modal-right-column .vue-scrollbar {
            margin-top: 10px;
            float: right;
            width: 205px;
        }
    }

    @media screen and (max-width: 765px) {
        .vue-message-search.vue-message-show {
            display: none;
        }
        .vue-search input[type="submit"].button-search-vue {
            width: 90px;
        }
        .vue-spinner-search {
            right: -15px;
        }
        .vue-message-search {
            right: 100px;
        }
    }

    @media screen and (max-width: 520px) {
        .vue-modal-left-column {
            display: none;
        }
        .vue-modal-container {
            height: 400px;
            padding: 10px;
            width: 280px;
        }
        .vue-modal-main-region {
            display: none;
        }
        .vue-modal-body {
            margin: 0;
        }
        .vue-modal-body .vue-modal-main-region p {
            padding-bottom: 10px;
        }
        .vue-modal-right-column .vue-main-input-city {
            width: 240px;
            background-position: 250px 8px;
        }
        .vue-modal-right-column {
            width: 280px;
        }
        .vue-modal-middle-column {
            width: 270px;
            max-height: 310px;
        }
        .vue-modal-close {
            right: 10px;
        }
        .vue-modal-right-column i.vue-input-active {
            left: 250px;
        }
        .vue-modal-middle-column .vue-scrollbar {
            max-height: 310px;
        }
    }

    @media screen and (max-width: 480px) {
        .vue-search input[type="submit"].button-search-vue {
            font-size: 18px;
        }
        .vue-search .icon-balloon {
            display: none;
        }
        .vue-search input[type="text"].vue-address-ddt.suggestions-input {
            padding: 17px 100px 13px 20px;
            font-size: 14px;
        }
    }

    @media screen and (max-height: 550px) {
        .vue-modal-container {
            height: 260px;
        }
        .vue-scrollbar {
            height: 150px;
        }
        .vue-modal-right-column {

        }
        .vue-modal-middle-column {

        }
    }
</style>

<template>
    <input type="text" v-model="value" ref="search">
</template>

<script>
    import $ from 'jquery';
    import 'suggestions-jquery';

    export default {
        props: {
            model: {
                required: true
            },
            coordinates: {},
            options: {
                type: Object,
                default: {
                    type: 'ADDRESS',
                    addon: 'none'
                }
            }
        },
        data() {
            return {
                value: '',
                id: '',
                coords: {
                    latitude: '',
                    longitude: ''
                },
            }
        },
        mounted() {
            this.value = this.model;
            this.initSuggestion();
        },
        watch: {
            coords: {
                handler() {
                    this.$emit('update:coordinates', this.coords);
                },
                deep: true
            },
            id() {
                this.$emit('update:id', this.id);
            },
            value() {
                this.$emit('update:model', this.value);
            },

            model() {
                this.value = this.model;
            }

        },
        methods: {

            initSuggestion() {
                const options = Object.assign({}, this.options/*, { onSelect: this.onSelect }*/);
                $(this.$el).suggestions(options);
            },


        }
    };
</script>

import Vue from 'vue';
import Search from './components/Search';

window.Event = new Vue();

new Vue({
    el: '#search-top',
    render: h => h(Search)
});

new Vue({
    el: '#search-bottom',
    render: h => h(Search)
});
